import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'
/**新增地点
localhost:8056/saas/wanwu-manage/web/place/addPlace
请求方式：POST
请求参数：placeName    必填   地点名称
        description   非必填  备注
 */
export function addPlace(params){
  return request({
    url:envInfo.bgApp.placePath + '/addPlace',
    method:'post',
    data: params
  })
}
/**获取地点（分页）
localhost:8056/saas/wanwu-manage/web/place/page
请求方式：GET
请求参数：placeName String N 地点名称
current Integer N 当前页
rowCount Integer N 每页显示数据
 */
export function getPlacePage(params){
  return request({
    url:envInfo.bgApp.placePath + '/page',
    method:'get',
    params
  })
}
/**查询地点详情
localhost:8056/saas/wanwu-manage/web/place/findPlaceDetailById
请求方式：GET
请求参数：placeId
 */
export function getPlaceDetail(params){
  return request({
    url:envInfo.bgApp.placePath + '/findPlaceDetailById' + params.placeId,
    method:'get',
    params
  })
}
/**修改地点
localhost:8056/saas/wanwu-manage/web/place/updatePlace
请求方式：POST
请求参数：id Long Y id编号
placeName String Y 地点名称
description String N备注
 */
export function updatePlace(params){
  return request({
    url:envInfo.bgApp.placePath + '/updatePlace',
    method:'post',
    data: params
  })
}
/**删除地点
localhost:8056/saas/wanwu-manage/web/place/deletePlace
请求方式：post
请求参数：placeId
 */
export function deletePlace(params){
  return request({
    url:envInfo.bgApp.placePath + '/deletePlace?placeId=' + params.placeId,
    method:'post',
    data:params
  })
}