var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container product-list-comp" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.createPlace } },
              [_vm._v("新增地点")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "header-filter-button",
            class: _vm.dataset.filterLine.show
              ? "filter-button-open"
              : "filter-button-close",
            on: {
              click: function ($event) {
                _vm.dataset.filterLine.show = !_vm.dataset.filterLine.show
              },
            },
          },
          [
            _c("i", { staticClass: "iconfont icon-loudoutu" }),
            _vm._v("\n      筛选\n    "),
          ]
        ),
      ]),
      _c(
        "div",
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "40%",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
          ]),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.createLoading,
                  expression: "createLoading",
                },
              ],
              ref: "placeForm",
              attrs: {
                "label-position": "left",
                model: _vm.form,
                rules: _vm.formRules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "地点名称",
                    prop: "placeName",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.isEdit,
                      maxlength: "10",
                      "show-word-limit": true,
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    model: {
                      value: _vm.form.placeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "placeName", $$v)
                      },
                      expression: "form.placeName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleClose("placeForm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.is_click },
                  on: {
                    click: function ($event) {
                      return _vm.handleLocationSave("placeForm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }