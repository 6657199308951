<template>
  <!-- 地点管理 -->
  <div class="app-container product-list-comp">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="createPlace">新增地点</el-button>
        <!-- <el-tooltip
          class="item"
          effect="dark"
          :disabled="selectionList.length > 0"
          :content="$t('commons.multipleSelectionOne')"
          placement="right"
        >
          <el-button
            type="danger"
            :class="selectionList.length === 0 && 'not-allowed-button'"
            icon="el-icon-delete"
            @click="batchDelete"
            plain
            >{{ $t("commons.delete") }}
          </el-button>
        </el-tooltip> -->
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>

    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <!-- 地点弹出框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ title }}
      </template>
      <el-form
        v-loading="createLoading"
        label-position="left"
        :model="form"
        ref="placeForm"
        :rules="formRules"
      >
        <el-form-item
          label="地点名称"
          prop="placeName"
          :label-width="formLabelWidth"
        >
          <el-input
            :disabled="isEdit"
            v-model="form.placeName"
            maxlength="10"
            :show-word-limit="true"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose('placeForm')">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="handleLocationSave('placeForm')"
          :loading="is_click"
          >{{ $t("commons.save") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import { Message } from "element-ui";

import {
  addPlace,
  getPlacePage,
  getPlaceDetail,
  updatePlace,
  deletePlace,
} from "@/api/ruge/vlink/wanwu/locationManagement";
export default {
  name: "LocationManager",
  components: {
    finalTable,
  },
  data() {
    const validatePlaceName = (rule, value, callback) => {
      // eslint-disable-next-line
      const regPlaceName = /^[\u4E00-\u9FA5A-Za-z0-9，。,（）、().\-\/]+$/;
      if (value === "") {
        callback(new Error("地点名称为必填字段"));
      } else if (!regPlaceName.test(value)) {
        callback(new Error("地点名称仅支持汉字、大小写英文，数字或标点符号。"));
      } else {
        callback();
      }
    };
    return {
      is_click: false,
      loadingFlag: false,
      formLabelWidth: "80px",
      //组件绑定的数据
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          // { prop: "serialNumber", label: "序号", width: "" },
          { prop: "placeName", label: "地点名称", width: "" },
          { prop: "siteNum", label: "站点数量", width: "" },
          { prop: "operation", label: "操作", width: "115" },
        ],
        tableData: [], //表格数据
        // 搜索行配置
        searchLineConfig: {
          placeName: {
            type: "input",
            label: "地点名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入地点名称",
            prefixIcon: "el-icon-search",
          },
          // operation: {
          //   type: "button",
          //   filterCount: "",
          //   actionType: "clickEvent",
          //   eventName: "showMoreFilters",
          //   label: "更多筛选",
          // },
        },
        // 表格内容配置
        detailConfig: {
          creationDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
                enableProp: "siteNum",
                enableValue: 0,
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          placeName: {
            inline: true,
            value: "",
          },
        },
        //页数
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      //接口请求参数
      listQuery: {
        placeName: null,
        current: 1,
        rowCount: 10,
      },
      //新增接口请求参数
      //地点弹出框
      dialogVisible: false, //弹出框
      createLoading: false, //弹出框表单的loading
      title: null,
      isEdit: false,
      //弹出框绑定的表单
      form: {
        placeName: null,
        description: null,
        placeId: null,
      },
      //弹出框绑定的表单规则
      formRules: {
        placeName: [
          // {
          //   required: true,
          //   trigger: ["blur"],
          //   message: "地点名称为必填字段",
          // },
          // {
          //   pattern: /^[\u4E00-\u9FA5A-Za-z0-9，。,（）、().\-\/]+$/,
          //   message: "地点名称仅支持汉字、大小写英文，数字或标点符号。",
          //   trigger: ["blur", "change"],
          // },

          {
            required: true,
            validator: validatePlaceName,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },

  watch: {
    loadingFlag(val) {
      this.$refs.finalTableRef.loadingToggle(val);
    },
  },
  mounted() {
    this.findLocationPage();
  },
  methods: {
    findLocationPage() {
      this.loadingFlag = true;
      const requestParams = { ...this.listQuery };
      getPlacePage(requestParams)
        .then((res) => {
          if (res && res.rows && res.rows.length > 0) {
            // let resultList = res.rows;
            // resultList.forEach((item) => {
            //   item.status = item.status ? "ENABLE" : "DIASABLE";
            // });
            this.dataset.pageVO.total = res.total;
            this.dataset.tableData = res.rows;
          } else {
            this.dataset.pageVO.total = 0;
            this.dataset.tableData = [];
          }
          this.loadingFlag = false;
        })
        .catch(() => {
          this.loadingFlag = false;
        });
    },
    //引入组件的那个方法
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.listQuery = { ...this.listQuery, ...datas.params };
        this.listQuery.current = 1;
        this.findLocationPage();
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.findLocationPage();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editLocation(datas.row);
            break;
          case "delete":
            this.deleteLocation(datas.row);
            break;
        }
      }
    },
    //编辑功能
    editLocation(row) {
      this.loadingFlag = false;
      this.dialogVisible = true;
      this.title = "编辑地点";
      this.isEdit = false;
      this.form = { ...row };
    },

    //新增/编辑-地点弹出框-保存按钮
    handleLocationSave() {
      this.$refs["placeForm"].validate((v) => {
        if (v) {
          this.is_click = true;
          setTimeout(() => {
            this.is_click = false;
          }, 2000);
          this.createLoading = false;
          let requestParams = { ...this.form };
          if (!this.form.placeId) {
            addPlace(requestParams)
              .then((res) => {
                if (res.code == 200) {
                  // 成功提示
                  this.$message({
                    message: this.$t("message.saveSuccess"),
                    type: "success",
                  });

                  this.dialogVisible = false;
                  this.findLocationPage();
                } else if (
                  res.code == 500 &&
                  res.msg == "添加失败，已存在的地点名称"
                ) {
                  // 成功提示
                  this.$message({
                    message: this.$t("添加失败，已存在的地点名称"),
                    type: "error",
                  });
                }
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            updatePlace(requestParams)
              .then((res) => {
                if (res.code == 200) {
                  // 成功提示
                  this.$message({
                    message: this.$t("message.saveSuccess"),
                    type: "success",
                  });
                  this.dialogVisible = false;
                  this.findLocationPage();
                } else if (
                  res.code == 500 &&
                  res.msg == "添加失败，已存在的地点名称"
                ) {
                  // 成功提示
                  this.$message({
                    message: this.$t("添加失败，已存在的地点名称"),
                    type: "error",
                  });
                }
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    //删除功能(删除功能带参数)
    deleteLocation(row) {
      // if (row.siteNum == 0) {
      this.$confirm(
        row.siteNum == 0
          ? this.$t("location.deleteConfirm")
          : this.$t("location.deleteConfirm1") +
              row.siteNum +
              this.$t("location.deleteConfirm2"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deletePlace({ placeId: row.placeId }).then(() => {
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findLocationPage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
      // } else {
      //   this.$confirm(
      //     this.$t("location.deleteConfirm1") +
      //       row.siteNum +
      //       this.$t("location.deleteConfirm2"),
      //     this.$t("commons.warning"),
      //     {
      //       confirmButtonText: this.$t("commons.confirm"),
      //       cancelButtonText: this.$t("commons.cancel"),
      //       type: "warning",
      //     }
      //   )
      //     .then(() => {
      //       deletePlace({ placeId: row.placeId }).then(() => {
      //         this.$message({
      //           message: this.$t("message.deleteSuccess"),
      //           type: "success",
      //         });
      //         this.findLocationPage();
      //       });
      //     })
      //     .catch((error) => {
      //       console.log(`未删除，原因 => ${error}`);
      //     });
    },
    //添加地点
    createPlace() {
      this.title = "新增地点";
      this.isEdit = false;
      this.form = {
        placeName: null,
      };
      this.dialogVisible = true;
    },
    //批量删除
    // batchDelete() {
    //   if (this.selectionList.length < 1) {
    //     return;
    //   }
    //   //获取总点位:获取返回的数据的点位相加
    //  var totalPointNumber=1;
    //   this.$confirm(
    //    this.$t("location.deleteConfirm1")+totalPointNumber+this.$t("location.deleteConfirm2"),
    //     this.$t("commons.warning"),
    //     {
    //       confirmButtonText: this.$t("commons.confirm"),
    //       cancelButtonText: this.$t("commons.cancel"),
    //       type: "warning",
    //     }
    //   )
    //     .then(() => {
    //       batchDeleteProduct({
    //         productIdList,
    //       }).then(() => {
    //         // 成功提示
    //         this.$message({
    //           message: this.$t("message.deleteSuccess"),
    //           type: "success",
    //         });
    //         this.findProductPage();
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(`未删除，原因 => ${error}`);
    //     });
    // },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="less">
.product-list-comp {
  padding: 32px 24px;
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
  .row-a-link {
    color: #409eff;
  }
  .advance-filter-container {
    height: 100%;
    width: 100%;
    padding: 32px 32px 96px 32px;
    position: relative;
    .button-container {
      width: 100%;
      height: 64px;
      line-height: 64px;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      .filter-button {
        text-align: center;
        cursor: pointer;
        background-color: #0486fe;
        color: #fff;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        flex: 2;
      }
      .clear-button {
        border: 1px solid #c0c4cc;
        text-align: center;
        cursor: pointer;
        background-color: #fff;
        color: #606266;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        flex: 1;
      }
    }
  }
  /deep/ .el-drawer__header {
    padding-left: 32px;
  }
  /deep/ .el-drawer__header > :first-child {
    outline: 0;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #303133;
  }
  /deep/ .display-none-row {
    display: none;
  }
  // 设置form的label的样式
  /deep/ .el-form-item__label {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #606266;
  }

  /deep/ .el-range-separator {
    min-width: 30px;
  }
  /deep/ .el-table .cell {
    padding-right: 0 !important;
  }
  /deep/ .el-pagination .el-select .el-input {
    margin-right: 0;
  }
  /deep/ .el-pagination__sizes {
    float: right;
    margin-right: 0;
  }
  /deep/ .el-pagination {
    padding-right: 0;
  }
  /deep/ .el-pagination__total {
    float: right;
  }
  /deep/ .el-dialog__header {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #303133;
    padding: 20px 20px 10px 32px;
  }
  // /deep/ .el-dialog__wrapper {
  //   margin-top: 30vh;
  // }
}
</style>
